import { DOMAINS_CONFIG } from "app/services/restaurant.service";

export class Address {
	public address: string;
	public cap: string;
	public city: string;
	public province: string;
}
export class WalletInfo {
	public creationDate: string;
	public pubKey: string;
}
export class UserContacts {
	public mobilePhone?: string;
	public fixedPhone?: string;
	public pec?: string;
	public alternativeEmail?: string;
}
export class UserCompany {
	public company: string;
	public organization: string;
	public address?: Address;
}

export class ClaimedNFT {
	cert_id: any;
	certIdFieldName?: string;
	domain: DOMAINS_CONFIG;
	claimedFieldName?: string;
}
export class UserProfile {
	public id: string;
	public userId: string;
	public avatarId: any;
	public firstName: string;
	public lastName: string;
	public email: string;
	public roles: string[];
	public address: Address;
	public fiscalCode: string;
	public contacts?: UserContacts;
	public lastUpdateDate: string;
	public walletInfo?: WalletInfo;
	public company?: UserCompany;
	public enabledDashboards: Dashboard[] = [];
	public claimedNFT: ClaimedNFT[] = [];
	private isInRole = (role: Roles): boolean => {
		return this.roles.includes(role.toLowerCase());
	}
}

export enum Roles {
	USER = "user",
	COMPANY_ADMIN = "company_admin",
	ADMIN = "admin",
	DEFAULT = "default-roles-posti",
	UMA_AUTH = "uma_authorization",
	OFF_ACCESS = "offline_access",
	ROMANESCO_MERCHANT = "romanesco_merchant"
}

export enum Dashboard {
	ROMANESCO = "ROMANESCO",
	PECORA_NERA = "PECORA_NERA",
	PECORA_NERA2 = "PECORA_NERA2",
	GIUSTA = "GIUSTA",
	EVALUATION_ROMANESCO = "VALUTATORI_ROMANESCO",
	WALLET_DELEGATION = "WALLET_DELEGATION",
	HQFAgricola = "HQFAgricola",
	casearia = "Casearea Agri In",
	casearia_pecorino = "Casearea Agri In - Pecorino Romano",
	agricola_san_maurizio = "Agricola San MAurizio",
	USER_LIST_ADMIN = "Amministrazione utenze",
	biolu = "Biolu"
}