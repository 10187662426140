import { Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseListTablePage } from 'app/common/components/pages/base-list-table.page';
import { DataTableAction, DataTableColumn, ListTableManager } from 'app/common/components/table/types';
import { ModalOptions } from 'app/common/models/models';
import { ModalService } from 'app/common/services/modal.service';
import { DataTableUtils } from 'app/common/utils/data-table.utils';
import { Roles } from 'app/models/profile.models';
import { Restaurant } from 'app/models/restaurant.model';
import { AddACRToEntityModal, EntityDescription } from 'app/pages/admin/acr-components/add-acr-to-entity.modal';
import { domainConfig } from 'app/services/DomainConfigProvider';
import { ProfileService } from 'app/services/profile.service';
import { DOMAINS_CONFIG, RestaurantProvider } from 'app/services/restaurant.service';
import { environment } from 'environments/environment';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CertificationBaseFormPage } from '../CertificationBaseFormPage';
import { BASE_FORM_PATH } from '../routing-constants';

class TestCert extends CertificationBaseFormPage {

	constructor(_restaurantProvider: RestaurantProvider, domainConfig) {
		super(_restaurantProvider);
		this.certificationConfig = environment.certificationConfig.DEFAULT_CONFIG;
		this.currentDomainConfig = domainConfig;
	}

	public certify(data): Promise<any>{
		return this.certifyData(data);
		// return this._restaurantProvider.getRestaurant(this.currentDomainConfig.domainId,entityId)
		// .pipe(
		// 	map((r) => {
		// 		return this.certifyData(r).then((certResult) => {
		// 			console.log("certificato creato per " + r.id);
		// 		})
		// 	})
		// )
		
		
	}
}


@Component({
    selector: 'list-page',
    templateUrl: './list.page.html'
})
export class ListPage extends BaseListTablePage<Restaurant> {
	currentDomain;
	domainPath;
	downloadExcelEnabled = false;
	deleteEntityEnabled = false;
	numberOfAvailableItems: number = 0;
    public tableColumns: DataTableColumn[] = [];
    public tableActions: DataTableAction[] = [];
    public filters: Filters = new Filters();

    constructor(_activatedRoute: ActivatedRoute, private _restaurantService: RestaurantProvider, private _router: Router, private _titleService: Title, private _modalService: ModalService, private _profileService: ProfileService) {
        super(_activatedRoute, true);
        // this.createColumms();
        // this.createActions();

		// let data = ["0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0xe07a3da0764d3b16eb2600820d14091ae16626d5d0fe4d43ff7ddc78f29011b8",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x6d0b70b8e3b1139682f19af94a3e0ebb68c5035e4dcad742cef3ba8d324f9e72",
		// "0x6d0b70b8e3b1139682f19af94a3e0ebb68c5035e4dcad742cef3ba8d324f9e72",
		// "0x6d0b70b8e3b1139682f19af94a3e0ebb68c5035e4dcad742cef3ba8d324f9e72",
		// "0x6d0b70b8e3b1139682f19af94a3e0ebb68c5035e4dcad742cef3ba8d324f9e72",
		// "0x6d0b70b8e3b1139682f19af94a3e0ebb68c5035e4dcad742cef3ba8d324f9e72",
		// "0x6d0b70b8e3b1139682f19af94a3e0ebb68c5035e4dcad742cef3ba8d324f9e72",
		// "0x6d0b70b8e3b1139682f19af94a3e0ebb68c5035e4dcad742cef3ba8d324f9e72",
		// "0x6d0b70b8e3b1139682f19af94a3e0ebb68c5035e4dcad742cef3ba8d324f9e72",
		// "0x6d0b70b8e3b1139682f19af94a3e0ebb68c5035e4dcad742cef3ba8d324f9e72",
		// "0x6d0b70b8e3b1139682f19af94a3e0ebb68c5035e4dcad742cef3ba8d324f9e72",
		// "0x6d0b70b8e3b1139682f19af94a3e0ebb68c5035e4dcad742cef3ba8d324f9e72",
		// "0xb3479a3589792254157749950a608e7758e210ee586b7a2d9dfbcc17cf1f1945",
		// "0xb3479a3589792254157749950a608e7758e210ee586b7a2d9dfbcc17cf1f1945",
		// "0xb3479a3589792254157749950a608e7758e210ee586b7a2d9dfbcc17cf1f1945",
		// "0xb3479a3589792254157749950a608e7758e210ee586b7a2d9dfbcc17cf1f1945",
		// "0xb3479a3589792254157749950a608e7758e210ee586b7a2d9dfbcc17cf1f1945",
		// "0xb3479a3589792254157749950a608e7758e210ee586b7a2d9dfbcc17cf1f1945",
		// "0xb3479a3589792254157749950a608e7758e210ee586b7a2d9dfbcc17cf1f1945",
		// "0xb3479a3589792254157749950a608e7758e210ee586b7a2d9dfbcc17cf1f1945",
		// "0xb3479a3589792254157749950a608e7758e210ee586b7a2d9dfbcc17cf1f1945",
		// "0xb3479a3589792254157749950a608e7758e210ee586b7a2d9dfbcc17cf1f1945",
		// "0xb3479a3589792254157749950a608e7758e210ee586b7a2d9dfbcc17cf1f1945",
		// "0xb3479a3589792254157749950a608e7758e210ee586b7a2d9dfbcc17cf1f1945",
		// "0xb3479a3589792254157749950a608e7758e210ee586b7a2d9dfbcc17cf1f1945",
		// "0xb3479a3589792254157749950a608e7758e210ee586b7a2d9dfbcc17cf1f1945",
		// "0xb3479a3589792254157749950a608e7758e210ee586b7a2d9dfbcc17cf1f1945",
		// "0xb3479a3589792254157749950a608e7758e210ee586b7a2d9dfbcc17cf1f1945",
		// "0xb3479a3589792254157749950a608e7758e210ee586b7a2d9dfbcc17cf1f1945",
		// "0xb3479a3589792254157749950a608e7758e210ee586b7a2d9dfbcc17cf1f1945",
		// "0xb3479a3589792254157749950a608e7758e210ee586b7a2d9dfbcc17cf1f1945",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x9bd810eecd21b024cd4be8dbfbae39603ac592a74ec16d7e2cc41202ef865bc8",
		// "0x5ec904a2d02aef53bdd381c69ae5f0757b41fa9e7827c69a48e6f1b5bbcd7ef2",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0x5ec904a2d02aef53bdd381c69ae5f0757b41fa9e7827c69a48e6f1b5bbcd7ef2",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0x44c5438d782b5e058e51655d3ab12facb99d42c118e204642d37733dfc124ac4",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xaa9fc724e2b7375c086f596aa75a14e011a06f9f709ec009fad7f862c9ae2f77",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0xf5244384f376df4462d967ec504dbafb5a079f7184c97adee480482598ea8bcb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0x6c2fc712385ccac3d4e8ce57fd9b89af448d1a365f4a82bd26dc1a06bc3c8bfb",
		// "0xde11e624d66b4a3bcad601c8b330baa679929eed653e160fb35635276e4d0cc1",
		// "0xde11e624d66b4a3bcad601c8b330baa679929eed653e160fb35635276e4d0cc1",
		// "0xde11e624d66b4a3bcad601c8b330baa679929eed653e160fb35635276e4d0cc1",
		// "0xde11e624d66b4a3bcad601c8b330baa679929eed653e160fb35635276e4d0cc1",
		// "0xde11e624d66b4a3bcad601c8b330baa679929eed653e160fb35635276e4d0cc1",
		// "0xde11e624d66b4a3bcad601c8b330baa679929eed653e160fb35635276e4d0cc1",
		// "0xde11e624d66b4a3bcad601c8b330baa679929eed653e160fb35635276e4d0cc1",
		// "0xde11e624d66b4a3bcad601c8b330baa679929eed653e160fb35635276e4d0cc1",
		// "0xde11e624d66b4a3bcad601c8b330baa679929eed653e160fb35635276e4d0cc1",
		// "0xde11e624d66b4a3bcad601c8b330baa679929eed653e160fb35635276e4d0cc1",
		// "0xde11e624d66b4a3bcad601c8b330baa679929eed653e160fb35635276e4d0cc1",
		// "0xde11e624d66b4a3bcad601c8b330baa679929eed653e160fb35635276e4d0cc1",
		// "0xde11e624d66b4a3bcad601c8b330baa679929eed653e160fb35635276e4d0cc1",
		// "0xde11e624d66b4a3bcad601c8b330baa679929eed653e160fb35635276e4d0cc1",
		// "0xde11e624d66b4a3bcad601c8b330baa679929eed653e160fb35635276e4d0cc1",
		// "0xde11e624d66b4a3bcad601c8b330baa679929eed653e160fb35635276e4d0cc1",
		// "0xde11e624d66b4a3bcad601c8b330baa679929eed653e160fb35635276e4d0cc1",
		// "0xde11e624d66b4a3bcad601c8b330baa679929eed653e160fb35635276e4d0cc1",
		// "0xde11e624d66b4a3bcad601c8b330baa679929eed653e160fb35635276e4d0cc1",
		// "0xde11e624d66b4a3bcad601c8b330baa679929eed653e160fb35635276e4d0cc1",
		// "0xde11e624d66b4a3bcad601c8b330baa679929eed653e160fb35635276e4d0cc1",
		// "0xde11e624d66b4a3bcad601c8b330baa679929eed653e160fb35635276e4d0cc1",
		// "0xde11e624d66b4a3bcad601c8b330baa679929eed653e160fb35635276e4d0cc1",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0xa74b490f52ebbb21b87c29fccf97548156ab8ba2875d308774bd54f958763108",
		// "0x5f08bef0c5059dc0f9a7cfc8d7bbc2dac95a39a118132e3f282279fc249e4ab3",
		// "0x5f08bef0c5059dc0f9a7cfc8d7bbc2dac95a39a118132e3f282279fc249e4ab3",
		// "0x5f08bef0c5059dc0f9a7cfc8d7bbc2dac95a39a118132e3f282279fc249e4ab3",
		// "0x5f08bef0c5059dc0f9a7cfc8d7bbc2dac95a39a118132e3f282279fc249e4ab3",
		// "0x5f08bef0c5059dc0f9a7cfc8d7bbc2dac95a39a118132e3f282279fc249e4ab3",
		// "0x5f08bef0c5059dc0f9a7cfc8d7bbc2dac95a39a118132e3f282279fc249e4ab3",
		// "0x5f08bef0c5059dc0f9a7cfc8d7bbc2dac95a39a118132e3f282279fc249e4ab3",
		// "0x5f08bef0c5059dc0f9a7cfc8d7bbc2dac95a39a118132e3f282279fc249e4ab3",
		// "0x5f08bef0c5059dc0f9a7cfc8d7bbc2dac95a39a118132e3f282279fc249e4ab3",
		// "0x5f08bef0c5059dc0f9a7cfc8d7bbc2dac95a39a118132e3f282279fc249e4ab3",
		// "0x5f08bef0c5059dc0f9a7cfc8d7bbc2dac95a39a118132e3f282279fc249e4ab3",
		// "0x5f08bef0c5059dc0f9a7cfc8d7bbc2dac95a39a118132e3f282279fc249e4ab3",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0xee1bd5472ac6fa0eaa29507bd9e15529bb29290cba6068c60984803d6fc0fb2e",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0x60f866976dd0384fe8f50aa2875d383e2695e5671c51aaa6d1d3447475a891cd",
		// "0xa53201e8445ba5faf199ba27ea12ad7dc0921f79631de666251e2ec56caee224",
		// "0xa53201e8445ba5faf199ba27ea12ad7dc0921f79631de666251e2ec56caee224",
		// "0xa53201e8445ba5faf199ba27ea12ad7dc0921f79631de666251e2ec56caee224",
		// "0xa53201e8445ba5faf199ba27ea12ad7dc0921f79631de666251e2ec56caee224",
		// "0xa53201e8445ba5faf199ba27ea12ad7dc0921f79631de666251e2ec56caee224",
		// "0xa53201e8445ba5faf199ba27ea12ad7dc0921f79631de666251e2ec56caee224",
		// "0xa53201e8445ba5faf199ba27ea12ad7dc0921f79631de666251e2ec56caee224",
		// "0xa53201e8445ba5faf199ba27ea12ad7dc0921f79631de666251e2ec56caee224",
		// "0xa53201e8445ba5faf199ba27ea12ad7dc0921f79631de666251e2ec56caee224",
		// "0xa53201e8445ba5faf199ba27ea12ad7dc0921f79631de666251e2ec56caee224",
		// "0x61a445aa849ec9e11418190551b5cc7246fbcd24747fb32338b81fb542b56b1b",
		// "0x61a445aa849ec9e11418190551b5cc7246fbcd24747fb32338b81fb542b56b1b",
		// "0x61a445aa849ec9e11418190551b5cc7246fbcd24747fb32338b81fb542b56b1b",
		// "0x61a445aa849ec9e11418190551b5cc7246fbcd24747fb32338b81fb542b56b1b",
		// "0x61a445aa849ec9e11418190551b5cc7246fbcd24747fb32338b81fb542b56b1b",
		// "0x61a445aa849ec9e11418190551b5cc7246fbcd24747fb32338b81fb542b56b1b",
		// "0x61a445aa849ec9e11418190551b5cc7246fbcd24747fb32338b81fb542b56b1b",
		// "0x61a445aa849ec9e11418190551b5cc7246fbcd24747fb32338b81fb542b56b1b",
		// "0x61a445aa849ec9e11418190551b5cc7246fbcd24747fb32338b81fb542b56b1b",
		// "0x61a445aa849ec9e11418190551b5cc7246fbcd24747fb32338b81fb542b56b1b",
		// "0x61a445aa849ec9e11418190551b5cc7246fbcd24747fb32338b81fb542b56b1b",
		// "0x61a445aa849ec9e11418190551b5cc7246fbcd24747fb32338b81fb542b56b1b",
		// "0x61a445aa849ec9e11418190551b5cc7246fbcd24747fb32338b81fb542b56b1b",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x41a27da0e906a836cd24e6504485093bcd1c46cd64356ff421da01ea84bdbcc9",
		// "0x42486154b00276c601e380d8991b0728c59fa6aa3567393de3bbf9c94ced1fa9",
		// "0x42486154b00276c601e380d8991b0728c59fa6aa3567393de3bbf9c94ced1fa9",
		// "0x42486154b00276c601e380d8991b0728c59fa6aa3567393de3bbf9c94ced1fa9",
		// "0x42486154b00276c601e380d8991b0728c59fa6aa3567393de3bbf9c94ced1fa9",
		// "0x42486154b00276c601e380d8991b0728c59fa6aa3567393de3bbf9c94ced1fa9",
		// "0x42486154b00276c601e380d8991b0728c59fa6aa3567393de3bbf9c94ced1fa9",
		// "0x42486154b00276c601e380d8991b0728c59fa6aa3567393de3bbf9c94ced1fa9",
		// "0x42486154b00276c601e380d8991b0728c59fa6aa3567393de3bbf9c94ced1fa9",
		// "0x42486154b00276c601e380d8991b0728c59fa6aa3567393de3bbf9c94ced1fa9",
		// "0x42486154b00276c601e380d8991b0728c59fa6aa3567393de3bbf9c94ced1fa9",
		// "0x42486154b00276c601e380d8991b0728c59fa6aa3567393de3bbf9c94ced1fa9",
		// "0x42486154b00276c601e380d8991b0728c59fa6aa3567393de3bbf9c94ced1fa9",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0xefcc71c8ab92a3e1f27a0e9f76d4994d3326276d5005431e5a3b6e3482dfef6d",
		// "0x41e2f35174cda0c85a360b9f0b08371a55bac190819ff487fbfff61b8714700e",
		// "0x41e2f35174cda0c85a360b9f0b08371a55bac190819ff487fbfff61b8714700e",
		// "0x41e2f35174cda0c85a360b9f0b08371a55bac190819ff487fbfff61b8714700e",
		// "0x41e2f35174cda0c85a360b9f0b08371a55bac190819ff487fbfff61b8714700e",
		// "0x41e2f35174cda0c85a360b9f0b08371a55bac190819ff487fbfff61b8714700e",
		// "0x41e2f35174cda0c85a360b9f0b08371a55bac190819ff487fbfff61b8714700e",
		// "0x41e2f35174cda0c85a360b9f0b08371a55bac190819ff487fbfff61b8714700e",
		// "0x41e2f35174cda0c85a360b9f0b08371a55bac190819ff487fbfff61b8714700e",
		// "0x41e2f35174cda0c85a360b9f0b08371a55bac190819ff487fbfff61b8714700e",
		// "0x41e2f35174cda0c85a360b9f0b08371a55bac190819ff487fbfff61b8714700e",
		// "0x41e2f35174cda0c85a360b9f0b08371a55bac190819ff487fbfff61b8714700e",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5",
		// "0x9b355e27592139497804a3d07fa057119afb20c4ef07141478838da93a476ed5"
		// ];

		// data = data.filter((value, index, self) => {
		// 	return self.indexOf(value) === index;
		// })
		// data.forEach(el => {
		// 	console.log("\"" + el + "\",");
			
		// })
		
    }

	private path = (obj,path) => {
        try{
            return eval("obj."+path);
        } catch(e) {
            return undefined;
        }
    }

	initialize = (): void => {

		this.on(this.activatedRoute.paramMap.subscribe(queryParams => {
			if (this.currentDomain && domainConfig[queryParams.get("domain")]) {
				this.downloadExcelEnabled = domainConfig[queryParams.get("domain")].downloadExcelEnabled;
				this.deleteEntityEnabled =domainConfig[queryParams.get("domain")].deleteEntityEnabled
				this.createColumms(queryParams.get("domain"));
				this.createActions(queryParams.get("domain"));
				this._titleService.setTitle(domainConfig[queryParams.get("domain")].title)
				let favIcon: HTMLLinkElement = document.querySelector('#appIcon');
				favIcon.href = domainConfig[queryParams.get("domain")].pageIcon;
			}
		}));
    }

	get domainName() {
		if (this.currentDomain && domainConfig[this.currentDomain]) {
			return domainConfig[this.currentDomain].name;
		}
	}
	get entityName() {
		if (this.currentDomain && domainConfig[this.currentDomain]) {
			return domainConfig[this.currentDomain].entityLabel;
		}
	}
   res;
   roma = [];
   milano = [];
   torino = [];
	private getRestaurant = (): Observable<Restaurant[]> => {
		// let certProvider: TestCert = new TestCert(this._restaurantService);

		return new Observable(observer => {
			this.on(this.activatedRoute.paramMap.subscribe(queryParams => {
				this.currentDomain = domainConfig[queryParams.get("domain")].domainId;
				let fields: string[] = domainConfig[queryParams.get("domain")].fields.map((element) => {return element.id})
				this._restaurantService.getAll(this.currentDomain, fields).subscribe((result) => {
					let toReturn = [];
					if (result) {
						this.numberOfAvailableItems = result.length;
						toReturn = result.map((element) => {
							return domainConfig[queryParams.get("domain")].map(element);
						})
					}
					this.res = result.sortAsc((v) => {
						return v.sign;
					})
					this.res = result;
					observer.next(toReturn);
				})
			}));
		})
		
	}

    protected getDataTableManager = (): ListTableManager<Restaurant> => {
        return new ListTableManager(this.getRestaurant)
    }

    private createColumms = (domain): void => {
		let filterKeys = [];

		domainConfig[domain].fields.forEach(element => {
			if (!element.hidden)
				this.tableColumns.push(element.dataTableField);
			if (element.filter)
				filterKeys.push(element.id);
		});
		if (filterKeys.length > 0) this.filterKeys = filterKeys;
    }

    private createActions = (domain): void => {
        let button = new DataTableAction();
        button.funcToInvoke = this.edit;
        button.label = "Aggiorna dati";
        button.icon = "ft-edit";
        this.tableActions.push(button);

		if (this.deleteEntityEnabled) {
			button = new DataTableAction();
			button.funcToInvoke = this.delete;
			button.label = "Elimina";
			button.icon = "ft-trash-2";
			this.tableActions.push(button);
		}
		

		if (domainConfig[domain].showBlockChainUrl) {
			button = new DataTableAction();
			button.funcToInvoke = this.showBlockchainCert;
			button.label = "Visualizza certificato in blockchain";
			button.icon = "fas fa-shield-alt";
			this.tableActions.push(button);
		}

		if (domainConfig[domain].landingPageUrl) {
			button = new DataTableAction();
			button.funcToInvoke = this.showLandigPage;
			button.label = "Visualizza Landing Page";
			button.icon = "fas fa-link";
			this.tableActions.push(button);
		}
		if (this._profileService.isInRole(Roles.ADMIN)) {
			button = new DataTableAction();
			button.funcToInvoke = this.addACR;
			button.label = "Utenti con accesso";
			button.icon = "fas fa-user-shield";
			this.tableActions.push(button);
		}
    }
	public addACR = (row: Restaurant): void => {
		let modalOption: ModalOptions<boolean> = {
			size: 'xl',
			callback: (res) => {}
		}
		let data: EntityDescription = {
			id: row.id,
			entityName: row.sign,
			entityDomain: domainConfig[this.currentDomain].domainId
		}
		this._modalService.show(AddACRToEntityModal, data, modalOption);
	}
	public showBlockchainCert = (row: Restaurant): void => {
		let id = this.path(row, domainConfig[this.currentDomain].certIdField);
		let url = domainConfig[this.currentDomain].blockchainUrlVerify.replaceAll("{id}", id).replaceAll("{domain}", this.currentDomain);
		window.open(url, "_blank");
	}
	public showLandigPage = (row: Restaurant): void => {
		let id = this.path(row, domainConfig[this.currentDomain].certIdField);
		let url = domainConfig[this.currentDomain].landingPageUrl.replaceAll("{id}", id);
		window.open(url, "_blank");
	}
    public edit = (row: Restaurant): void => {
		this.on(this.activatedRoute.paramMap.subscribe(qp => {
			const queryParams = {};
			queryParams['returnUrl'] = this.getReturnUrl();
       		 this._router.navigate([BASE_FORM_PATH, qp.get("domain") , row.id], { queryParams: queryParams });
		}));

        
    }
	public delete = (row: Restaurant): void => {
		let callback = (data) => {
			if (data) {
				this.on(this.activatedRoute.paramMap.subscribe(qp => {
					this._restaurantService.deleteRestaurant(<any>qp.get("domain") ,row.id).subscribe((result) => {
						this.dataTableManager.startReload();
					})

				}));

				
			}
		}
		this._modalService.showConfirm("common.dialogs.alertRemoveItemTitle", "common.dialogs.alertRemoveItemMessage", {
			callback: callback,
			size: "md"
		});
    }
	public addEntity = () => {
		this.on(this.activatedRoute.paramMap.subscribe(qp => {
			const queryParams = {};
			queryParams['returnUrl'] = this.getReturnUrl();
       		 this._router.navigate([BASE_FORM_PATH, qp.get("domain")], { queryParams: queryParams });
		}));
	}

	public downloadCSV = () => {
		if (this.currentDomain) {
			let url = environment.services.restaurantService + "/export/csv/" + this.currentDomain ;
			window.open(url, "_blank");			
		}

	}
	openFipeExcel() {
		window.open("https://docs.google.com/spreadsheets/d/1PsHEbXq6vvA9DJEV6mLRTFppkjRmB6nG/edit?usp=sharing&ouid=107316743256498418483&rtpof=true&sd=true", "_blank");
		
	}
	public downloadExcel = () => {
		// const queryParams = {};
        // queryParams['returnUrl'] = this.getReturnUrl();
        // this._router.navigate([this.currentDomain, "import"], { queryParams: queryParams });

		if (this.currentDomain) {
			let url = environment.services.restaurantService + "/export/download/excel/" + this.currentDomain ;
			window.open(url, "_blank");			
		}

	}
	private codes = [
		"FU00034",
"FV00034",
"FW00034",
"FX00034",
"FY00034",
"FZ00034",
"GA00034",
"GB00034",
"GC00034",
"GD00034",
"GE00034",
"GF00034",
"GG00034",
"GH00034",
"GI00034",
"GJ00034",
"GK00034",
"GL00034",
"GM00034",
"GN00034",
"GO00034",
"GP00034",
"GQ00034",
"GR00034",
"GS00034",
"GT00034",
"GU00034",
"GV00034",
"GW00034",
"GX00034",
"GY00034",
"GZ00034",
"HA00034",
"HB00034",
"HC00034",
"HD00034",
"HE00034",
"HF00034",
"HG00034",
"HH00034",
"HI00034",
"HJ00034",
"HK00034",
"HL00034",
"HM00034",
"HN00034",
"HO00034",
"HP00034",
"HQ00034",
"HR00034",
"HS00034",
"HT00034",
"HU00034",
"HV00034",
"HW00034",
"HX00034",
"HY00034",
"HZ00034",
"IA00034",
"IB00034",
"IC00034",
"ID00034",
"IE00034",
"IF00034",
"IG00034",
"IH00034",
"II00034",
"IJ00034",
"IK00034",
"IL00034",
"IM00034",
"IN00034",
"IO00034",
"IP00034",
"IQ00034",
"IR00034",
"IS00034",
"IT00034",
"IU00034",
"IV00034",
"IW00034",
"IX00034",
"IY00034",
"IZ00034",
"JA00034",
"JB00034",
"JC00034",
"JD00034",
"JE00034",
"JF00034",
"JG00034",
"JH00034",
"JI00034",
"JJ00034",
"JK00034",
"JL00034",
"JM00034",
"JN00034",
"JO00034",
"JP00034",
"JQ00034",
"JR00034",
"JS00034",
"JT00034",
"JU00034",
"JV00034",
"JW00034",
"JX00034",
"JY00034",
"JZ00034",
"KA00034",
"KB00034",
"KC00034",
"KD00034",
"KE00034",
"KF00034",
"KG00034",
"KH00034",
"KI00034",
"KJ00034",
"KK00034",
"KL00034",
"KM00034",
"KN00034",
"KO00034",
"KP00034",
"KQ00034",
"KR00034",
"KS00034",
"KT00034",
"KU00034",
"KV00034",
"KW00034",
"KX00034",
"KY00034",
"KZ00034",
"LA00034",
"LB00034",
"LC00034",
"LD00034",
"LE00034",
"LF00034",
"LG00034",
"LH00034",
"LI00034",
"LJ00034",
"LK00034",
"LL00034",
"LM00034",
"LN00034",
"LO00034",
"LP00034",
"LQ00034",
"LR00034",
"LS00034",
"LT00034",
"LU00034",
"LV00034",
"LW00034",
"LX00034",
"LY00034",
"LZ00034",
"MA00034",
"MB00034",
"MC00034",
"MD00034",
"ME00034",
"MF00034",
"MG00034",
"MH00034",
"MI00034",
"MJ00034",
"MK00034",
"ML00034",
"MM00034",
"MN00034",
"MO00034",
"MP00034",
"MQ00034",
"MR00034",
"MS00034",
"MT00034",
"MU00034",
"MV00034",
"MW00034",
"MX00034",
"MY00034",
"MZ00034",
"NA00034",
"NB00034",
"NC00034",
"ND00034",
"NE00034",
"NF00034",
"NG00034",
"NH00034",
"NI00034",
"NJ00034",
"NK00034",
"NL00034"
];
	private blackList = []
	private data = JSON.parse(
		`{"producerInfo":{"company":"ETICA Food Srl","city":"Roma","address":"Piazza Roma, 6 - Santa Severa","product":"Panettone NEW_PAT_2"},"generalInfo":{"productionLot":"L000002","productCode":"143.WE00030","photo":"61a66d070ec94d33a3d20f98","packagingDate":"2021-12-09T18:27:08.392Z","expiryDate":"2022-01-15T18:27:13.325Z"},"ingrediants":[{"ingrediant":"farina di farro","lot":"1030","producer":"bio company"},{"ingrediant":"malto di riso","lot":"210923","producer":"la Finestra sul Cielo"},{"ingrediant":"milele millefiori biologico","lot":null,"producer":"Natura in campo"},{"ingrediant":"olio extravergine d'oliva ","lot":"21286B","producer":"Crudolio"},{"ingrediant":"farina bio tipo1 semintegrale","lot":"245","producer":"Antico Molino Rosso"},{"ingrediant":"lievito madre","lot":null,"producer":"Michele Bonfitto"},{"ingrediant":"datteri","lot":null,"producer":"Fior di Loto"},{"ingrediant":"olio d semi di girasole pressato a freddo","lot":"210923","producer":"Baule Volante"},{"ingrediant":"tuorlo d'uovo","lot":"30112021","producer":"la Cardellina"},{"ingrediant":"uvetta sultanina","lot":" 19895-02-788","producer":"Eco NaturaSi"},{"ingrediant":"scorzette d'arancia","lot":null,"producer":"Natura in campo"},{"ingrediant":"bacche di vaniglia di Madagascar","lot":"04200/174141","producer":null},{"ingrediant":"sale marino integrale artigianale selezionato dal maitre du sel Paolo Santoro di Riserva del Mare Saline Culcasi","lot":"31122025","producer":null}]}`
		
		);
	
	public saveData() {
		console.log(this.data);
		
		
	}
	private saved=[];
	public async deleteAll() {
		let certProvider = new TestCert(this._restaurantService, domainConfig[this.currentDomain]);
		for (const code of this.codes) {
			
			let d: any = {};
			Object.assign(d,this.data);
			d.generalInfo.productCode = "152."+code;
			if (this.saved.indexOf(d.generalInfo.productCode) < 0) {
				await this._restaurantService.saveOrUpdateRestaurant(DOMAINS_CONFIG.NEW_PAT_2, d).toPromise().then(async (res: any) => {
					this.saved.push(d.generalInfo.productCode);
					// console.log("try to certify "+ res.generalInfo.productCode);
					await certProvider.certify(res).then((certResult) => {
						console.log("certificato creato per " + res.generalInfo.productCode);
						this.blackList.push(res.generalInfo.productCode);
					})
				})
			}
			else if (this.blackList.indexOf(d.generalInfo.productCode) < 0) {
					
				// console.log("try to certify "+ res.generalInfo.productCode);
				await certProvider.certify(d).then((certResult) => {
					console.log("certificato creato per " + d.generalInfo.productCode);
					this.blackList.push(d.generalInfo.productCode);
				})
			
			}
			
		}

		// this.res.forEach(element => {
		// 	this._restaurantService.deleteRestaurant(this.currentDomain, element.id).subscribe((result) => {
		// 		console.log("deleted " + element.id);
				
		// 	})
		// });
	}
}


class Filters {
}