import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectListitem } from 'app/common/models/models';
import { Dashboard, Roles, UserProfile } from 'app/models/profile.models';
import { ProfileService } from 'app/services/profile.service';


@Component({
	selector: 'edit-user-roles-form',
	templateUrl: './edit-user-roles.page.html'
})

export class EditUserRoleModal implements OnInit {
	pageLoaded = false;
	userProfile: UserProfile;
	formGroup: FormGroup;
	address: FormGroup;
	contacts: FormGroup;
	companyAddress: FormGroup;
	company: FormGroup;
	walletInfo: FormGroup;
	claimedNFT: FormGroup;

	public userRoles: SelectListitem[] = [];
	public dshboardsList: SelectListitem[] = [];

	privateKey: string;

	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	activeTab = "general";

	public data: any;
	title = "";

	constructor(private _userProfileService: ProfileService, private _fb: FormBuilder, private _activeModal: NgbActiveModal) {

		let products = [
			{id:  Dashboard.USER_LIST_ADMIN, name: "Amministrazione utenze"},
			{id:  Dashboard.EVALUATION_ROMANESCO, name: "Dashboard valutatori Romanesco"},
			{id:  Dashboard.ROMANESCO, name: "Dashboard Romanesco"},
			{id:  Dashboard.GIUSTA, name: "Dashboard Giusta"},
			{id:  Dashboard.PECORA_NERA, name: "Dashboard Pecora Nera"},
			{id:  Dashboard.PECORA_NERA2, name: "Dashboard Pecora Nera - sezione ispettori"},
			{id:  Dashboard.HQFAgricola, name: "Dashboard HQF Agricola"},
			{id:  Dashboard.casearia, name: "Casearea Agri In"},
			{id:  Dashboard.casearia_pecorino, name: "Casearea Agri In - Pecorino Romano"},
			{id:  Dashboard.agricola_san_maurizio, name: "Agricola San Maurizio"},
			{id:  Dashboard.biolu, name: "Biolu"},
			{id:  Dashboard.WALLET_DELEGATION, name: "Delega Posti per trasferimento proprietà"}
		];

		this.dshboardsList = products.map(m => new SelectListitem(m.id, m.name));

		let roles = [
			{id:  Roles.ADMIN, name: "Amministratore Posti"},
			{id: Roles.ROMANESCO_MERCHANT, name: "ristoratore Romanesco"},
			{id:  Roles.COMPANY_ADMIN, name: "Amministratore Azienda"},
			{id:  Roles.USER, name: "Utente"},
			{id:  Roles.DEFAULT, name: "Ruolo di default"},
			{id:  Roles.OFF_ACCESS, name: "Ruolo offline access"},
			{id:  Roles.UMA_AUTH, name: "Ruolo auth"}
		];

		this.userRoles = roles.map(m => new SelectListitem(m.id, m.name));
	}

	ngOnInit() {
		this._userProfileService.getProfile(this.data.id).subscribe((result) => {
			this.userProfile = result;
			this.initForm();
			this.formGroup.patchValue(this.userProfile);
			this.title = "Abilita accesso a " + result.firstName + " " + result.lastName;
			this.pageLoaded = true;
		})
	}



	private initForm = (): void => {

		this.address = this._fb.group({
			address: [null],
			cap: [null],
			city: [null],
			province: [null]
		});
		this.contacts = this._fb.group({
			mobilePhone: [null],
			fixedPhone: [null],
			pec: [null],
			alternativeEmail: [null]
		});


		this.companyAddress = this._fb.group({
			address: [null],
			cap: [null],
			city: [null],
			province: [null]
		});

		this.company = this._fb.group({
			company: [null],
			organization: [null],
			address: this.companyAddress
		});
		this.walletInfo = this._fb.group({
			creationDate: [null],
			pubKey: [null]
		});
		this.claimedNFT = this._fb.group({
			cert_id: [null],
			domain: [null],
			claimedFieldName: [null],
			certIdFieldName: []
			
		});
		this.formGroup = this._fb.group({
			userId: [null],
			avatarId: [null],
			firstName: [null],
			lastName: [null],
			email: [null],
			fiscalCode: [null],

			id: [null],
			lastUpdateDate: [null],
			roles: [[]],
			walletInfo: this.walletInfo,
			claimedNFT: this.claimedNFT,
			company: this.company,
			contacts: this.contacts,
			address: this.address,
			enabledDashboards: [[]]
		});
	}

	
	save = () => {
		this._userProfileService.saveOrUpdateProfile(this.formGroup.value).subscribe((result) => {
			this.userProfile = result;
			this._activeModal.close(true);
		})
	}

	close = () => {
		this._activeModal.close(false);
	}

}