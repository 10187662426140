export const BASE_FORM_PATH = "form/"
export const FORM_CHOICE = "form/form-choice"
export const GIUSTA_PATH = 'form/GIUSTA';
export const BONFIT_PATH = 'form/BONFIT';
export const PAT_PATH = 'form/NEW_PAT_2';
export const ROMANESCO_PATH = 'form/ROMANESCO';
export const PECORA_NERA_PATH = 'form/PECORA_NERA';
export const PECORA_NERA_2_PATH = 'form/PECORA_NERA_2';
export const FORM_SUBMISSION_RESULT = 'forms/submission-result';
export const ROMANESCO_2 = "evaluation-romanesco";

export const BASE_HQF_PATH = "form/hqfagricola_demo"
export const BASE_CASEARIA_PATH = "form/casearia"
export const BASE_CASEARIA_PECORINO_PATH = "form/casearia_pecorino"
export const BASE_AGRICOLA_S_MAURIZIO_PATH = "form/agricola_san_maurizio"

export const BASE_BIOLU_PATH = "form/biolu"
